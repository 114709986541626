import { MethodWithParameters } from '@rxap/pattern';
import { ControlOptions } from '@rxap/utilities';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListRemoteMethod
} from 'open-api/remote-methods/notification-accordion-subscribed-machines-controller-get-autocomplete-machine-option-list.remote-method';
import {
  UserControllerGetByUuidRemoteMethod
} from 'open-api-legacy/remote-methods/user-controller-get-by-uuid.remote-method';
import {
  UserSettingsAccordionDataSource
} from 'angular-components/layout/default-header/user-profile-icon/user-settings/user-settings-accordion.data-source';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class SubscribedMachinesAutocompleteMethod implements MethodWithParameters<ControlOptions, {
  parameters: { search: string }
}> {
  private companyUuid: string | undefined = undefined;

  private readonly getUser = inject(UserControllerGetByUuidRemoteMethod);
  public readonly accordionDataSource = inject(UserSettingsAccordionDataSource);
  public readonly data = toSignal(this.accordionDataSource.connect({id: 'to-signal-user-settings-accordion'}));

  private readonly getOptionList = inject(
    NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListRemoteMethod
  );

  private readonly route = inject(ActivatedRoute);

  async call(parameters: { parameters: { search: string } }): Promise<ControlOptions> {
    const userUuid = this.data()!.uuid;
    if (this.companyUuid === undefined) {
      this.companyUuid = (await this.getUser.call({ parameters: { uuid: userUuid } })).companyUuid;
    }
    return this.getOptionList.call({
      parameters: {
        uuid: userUuid,
        search: parameters.parameters.search,
        company: this.companyUuid!
      },
    });
  }

}
