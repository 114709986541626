import { Pipe, PipeTransform } from '@angular/core';
import { Company } from 'open-api-legacy/components/company';
import {
  CompanyControllerGetByUuidRemoteMethod,
} from 'open-api-legacy/remote-methods/company-controller-get-by-uuid.remote-method';

@Pipe({
  name: 'company',
  standalone: true,
})
export class CompanyPipe implements PipeTransform {

  constructor(private readonly getCompany: CompanyControllerGetByUuidRemoteMethod) {
  }

  public async transform(uuid: string | null): Promise<Company | null> {
    if (uuid) {
      return this.getCompany.call({parameters: {uuid}}) as any;
    }
    return null;
  }

}


