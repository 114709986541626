import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateWithTimeAgo',
  standalone: true,
})
export class DateWithTimeAgoPipe implements PipeTransform {

  public transform(value: Date | string | number | null | undefined, showDate = false, ...args: any[]): any {
    const datePipe = new DatePipe('en-US');
    const dateTime = datePipe.transform(value, ...args);

    if (value == null) {
      return dateTime;
    }

    const givenDate = new Date(value);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - givenDate.getTime();

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const years = Math.floor(days / 365);

    let timeAgo = '';
    if (seconds < 60) {
      timeAgo = $localize`${seconds} second(s) ago`;
    } else if (minutes < 60) {
      timeAgo = $localize`${minutes} minute(s) ago`;
    } else if (hours < 24) {
      // const remaningMinutes = minutes % 60;
      timeAgo = $localize`${hours} hour(s) ago`;
    } else if (days < 7) {
      // const remainingHours = hours % 24;
      timeAgo = $localize`${days} day(s) ago`;
    } else if (years < 1) {
      timeAgo = $localize`${days} day(s) ago`;
    } else {
      // const remainingDays = days % 365;
      timeAgo = $localize`${years} year(s) ago`;
    }

    if (showDate) {
      return `${dateTime} (${timeAgo})`;
    } else {
      return timeAgo;
    }
  }

}


