@if (loading()) {
  <mat-progress-bar class="py-2" mode="indeterminate"></mat-progress-bar>
}
<ng-template #errorTemplate let-error let-refresh="refresh">
  <rxap-data-source-error [error]="error" [refresh]="refresh"></rxap-data-source-error>
</ng-template>
<div *rxapDataSource="let panel from panelDataSource; errorTemplate: errorTemplate" class="flex flex-col gap-4">
  <eurogard-boxed-slide-toggle-control
    #alarmingToggle
    (ngModelChange)="toggleAlarming($event, alarmingToggle)"
    [disabled]="loading()"
    [ngModel]="panel.alarmingEnabled">
    <ng-container label i18n>Alarming</ng-container>
  </eurogard-boxed-slide-toggle-control>

  <eurogard-boxed-slide-toggle-control
    #pushNotificationsToggle
    (ngModelChange)="togglePushNotification($event, pushNotificationsToggle)"
    [disabled]="!panel.alarmingEnabled || loading()"
    [ngModel]="panel.pushNotificationEnabled">
    <ng-container label i18n>Push Notification</ng-container>
    <div class="inline-block ml-4" content>
      @if (panel.pushNotificationEnabled) {
        <button
          [disabled]="loading()"
          (click)="sendPushTestNotification()"
          color="primary"
          class="grow-0"
          mat-raised-button
          type="button"
        >
          <ng-container i18n>Test Notification</ng-container>
        </button>
      }
    </div>
  </eurogard-boxed-slide-toggle-control>

  <eurogard-boxed-slide-toggle-control
    #emailNotificationsToggle
    (ngModelChange)="toggleEmailNotification($event, emailNotificationsToggle)"
    [disabled]="!panel.alarmingEnabled || loading()"
    [ngModel]="panel.emailNotificationEnabled">
    <ng-container label i18n>Email Notification</ng-container>
    <div class="inline-block ml-4" content>
      @if (panel.emailNotificationEnabled) {
        <button
          [disabled]="loading()"
          (click)="sendEmailTestNotification()"
          color="primary"
          class="grow-0"
          mat-raised-button
          type="button"
        >
          <ng-container i18n>Test Notification</ng-container>
        </button>
      }
    </div>
  </eurogard-boxed-slide-toggle-control>

  <eurogard-boxed-slide-toggle-control
    #telegramNotificationsToggle
    (ngModelChange)="toggleTelegramNotification($event, telegramNotificationsToggle)"
    [disabled]="!panel.alarmingEnabled || loading()"
    [ngModel]="useTelegramAlarming()">
    <ng-container label i18n>Telegram Notification</ng-container>
    <div class="flex flex-col mt-4" content>
      <div *ngIf="hasCurrentCredentials" class="flex gap-4">
        <div class="flex gap-2"><span i18n>Current API Key:</span><span>{{ data().telegramApiToken }}</span></div>
        <div class="flex gap-2"><span i18n>Current Chat ID:</span><span>{{ data().telegramChatId }}</span></div>
      </div>
      <div class="flex items-center gap-4 mt-4">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label i18n>API Key</mat-label>
          <input
            type="text"
            [disabled]="loading() || !useTelegramAlarming()"
            [ngModel]="telegramAPIToken()"
            (ngModelChange)="telegramAPIToken.set($event)"
            placeholder="Enter API Key"
            i18n-placeholder
            matInput
            [required]="!hasCurrentCredentials"
          >
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label i18n>Chat ID</mat-label>
          <input
            type="text"
            [disabled]="loading() || !useTelegramAlarming()"
            [ngModel]="telegramChatId()"
            (ngModelChange)="telegramChatId.set($event)"
            placeholder="Enter Chat ID"
            i18n-placeholder
            matInput
            required
          >
        </mat-form-field>
        <button
          [disabled]="loading() || !useTelegramAlarming() || !telegramChatId() || (!hasCurrentCredentials && !telegramAPIToken())"
          (click)="saveTelegramCredentials()"
          color="primary"
          class="grow-0"
          mat-raised-button
          type="button"
        >
          {{ saveButtonLabel }}
        </button>
        @if (hasCurrentCredentials && useTelegramAlarming()) {
          <button
            [disabled]="loading()"
            (click)="sendTelegramTestNotification()"
            color="primary"
            class="grow-0"
            mat-raised-button
            type="button"
          >
            <ng-container i18n>Test Notification</ng-container>
          </button>
        }
      </div>
    </div>
  </eurogard-boxed-slide-toggle-control>
</div>
