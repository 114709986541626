<div [ngClass]="{
    'bg-lime-600/25 border-green-600': !disabled() && value(),
    'bg-gray-600/25 border-gray-600': disabled() || !value()
  }" class="rounded p-4 border">
  <mat-slide-toggle
    (ngModelChange)="onChange?.($event)"
    [disabled]="disabled()"
    [ngModel]="value()"
    labelPosition="after">
    <span class="pl-4">
      <ng-content select="[label]"></ng-content>
    </span>
  </mat-slide-toggle>
  <ng-content select="[content]"></ng-content>
</div>
