<h2 style="margin: 0" mat-dialog-title>{{ data.title ?? defaultTitle }}</h2>
<mat-dialog-content style="padding: 0">

  <div class="content">
    <div [innerHtml]="data.message | santization:'html'"></div>

    <div *ngIf="data.warning" class="flex items-center text-yellow-500" [class.mt-4]="data.message !== undefined">
      <mat-icon>warning</mat-icon>
      <div [innerHtml]="data.warning | santization:'html'"></div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="true" color="accent" mat-raised-button>{{ data.action ?? defaultAction }}</button>
  <button [mat-dialog-close]="false" i18n mat-button>Cancel</button>
</mat-dialog-actions>
