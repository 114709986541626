import { InjectionToken } from '@angular/core';
import { AutocompleteOptionsMethod, AutocompleteResolveMethod } from 'autocomplete-table-select';
import { ToMethod } from '@rxap/pattern';
import { noop } from '@rxap/utilities';

export const AUTOCOMPLETE_ITEM_AUTOCOMPLETE_RESOLVE_METHOD = new InjectionToken<AutocompleteResolveMethod>(
  'The resolve method for the autocomplete form control', {
    factory: () => ToMethod(noop) as any,
  });
export const AUTOCOMPLETE_ITEM_AUTOCOMPLETE_OPTIONS_METHOD = new InjectionToken<AutocompleteOptionsMethod>(
  'The options method for the autocomplete form control');
