import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { UserControllerMeGUIResponse } from '../responses/user-controller-me-gui.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'user-controller-meGUI',
    operation: '{"operationId":"user-controller-meGUI","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"deletedAt":{"type":"integer","format":"int64"},"username":{"type":"string"},"lastname":{"type":"string"},"firstname":{"type":"string"},"companyName":{"type":"string"},"companyUuid":{"type":"string","format":"uuid"},"company":{"$ref":"#/components/schemas/Company"},"primaryRoleValue":{"type":"integer","format":"int64"},"primaryRole":{"type":"string","format":"uuid"},"roles":{"type":"array","items":{"type":"string"}},"userInfoUuid":{"type":"string","format":"uuid"},"lastConnection":{"type":"string","format":"date-time"},"favouriteLayout":{"$ref":"#/components/schemas/Layout"},"receiveMessages":{"type":"boolean"},"receiveEmailMessages":{"type":"boolean"},"street":{"type":"string"},"zip":{"type":"string"},"city":{"type":"string"},"country":{"type":"string"},"phone":{"type":"string"},"comment":{"type":"string"},"privacyAccepted":{"type":"boolean"},"desktopDesignMode":{"type":"string","enum":[]},"mobileDesignMode":{"type":"string","enum":[]},"userLanguage":{"type":"string","enum":[]},"role":{"type":"string"},"vpnRoleList":{"type":"array","items":{"type":"string"}},"notificationChannel":{"type":"integer","format":"int64"},"telegramChatId":{"type":"string"},"telegramApiToken":{"type":"string"},"admin":{"type":"boolean"},"__updatedBy":{"type":"string"},"__updatedAt":{"type":"integer","format":"int64"},"__removedAt":{"type":"integer","format":"int64"},"__archived":{"type":"boolean"},"uuid":{"type":"string","format":"uuid"}}}}}}},"method":"get","path":"/user-controller/meGUI"}'
  })
export class UserControllerMeGUIRemoteMethod extends OpenApiRemoteMethod<UserControllerMeGUIResponse, void, void> {
  public override call(): Promise<UserControllerMeGUIResponse> {
    return super.call();
  }
}
