import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDisplayFromValue',
  standalone: true,
})
export class GetDisplayFromValuePipe implements PipeTransform {
  transform(
    options: Array<{ value: any; display: string }>,
    value: any
  ): string | undefined {
    return options.find((option) => option.value == value)?.display ?? 'unresolved';
  }
}
