import { RxapDataSource } from '@rxap/data-source';
import { Injectable, inject } from '@angular/core';
import { NotificationAccordionSubscribedMachinesControllerGetByIdResponse } from 'open-api/responses/notification-accordion-subscribed-machines-controller-get-by-id.response';
import { NotificationAccordionSubscribedMachinesControllerGetByIdRemoteMethod } from 'open-api/remote-methods/notification-accordion-subscribed-machines-controller-get-by-id.remote-method';
import { PanelAccordionDataSource } from '@rxap/data-source/accordion';

@Injectable()
@RxapDataSource('subscribed-machines-panel')
export class SubscribedMachinesPanelDataSource extends PanelAccordionDataSource<NotificationAccordionSubscribedMachinesControllerGetByIdResponse> {
  protected override readonly method = inject(NotificationAccordionSubscribedMachinesControllerGetByIdRemoteMethod);
}
