import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { CompanyControllerGetByUuidParameter } from '../parameters/company-controller-get-by-uuid.parameter';
import { CompanyControllerGetByUuidResponse } from '../responses/company-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'company-controller-getByUuid',
    operation: '{"operationId":"company-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"required":["name"],"type":"object","properties":{"uuid":{"type":"string","format":"uuid"},"name":{"type":"string"},"street":{"type":"string"},"zip":{"type":"string"},"city":{"type":"string"},"country":{"type":"string"},"contact":{"$ref":"#/components/schemas/User"},"nodeRedLicences":{"type":"integer","format":"int32"},"parent":{"type":"string","format":"uuid"},"comment":{"type":"string"},"customerRoot":{"type":"boolean"},"removedAt":{"type":"integer","format":"int64"},"__updatedBy":{"type":"string"},"__updatedAt":{"type":"integer","format":"int64"},"__removedAt":{"type":"integer","format":"int64"},"__archived":{"type":"boolean"},"isCompany":{"type":"boolean"},"updatedAt":{"type":"integer","format":"int64"},"deletedAt":{"type":"integer","format":"int64"}}}}}}},"method":"get","path":"/company-controller/get/{uuid}"}'
  })
export class CompanyControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<CompanyControllerGetByUuidResponse, CompanyControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<CompanyControllerGetByUuidParameter, void>): Promise<CompanyControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}
