import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TexteDetailsResponse } from 'open-api-legacy/responses/texte-details.response';

@Pipe({
  name: 'getTexteLabel',
  standalone: true,
})
export class GetTexteLabelPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID)
    private readonly locale: string,
  ) {
  }

  public transform(value: Array<TexteDetailsResponse>, language?: number): string | undefined {
    if (language === undefined) {
      language = this.locale === 'en-US' ? 1 : 0;
    }
    return value.find(v => Number(v.language) === language)?.label;
  }

}


