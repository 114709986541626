import { ChangeDetectionStrategy, Component, computed, effect, forwardRef, Input, input, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@rxap/forms';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDropList,
  CdkDropListGroup,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  MatListItem,
  MatListItemTitle,
  MatListModule,
} from '@angular/material/list';
import { equals, getIdentifierPropertyValue, hasIdentifierProperty } from '@rxap/utilities';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';
import { ToNumberPipe } from 'eurogard-pipes';
import { AutocompleteFormComponent } from 'angular-forms/autocomplete-form/autocomplete-form.component';

export interface Priority {
  priority: number;
}

export type PriorityItem<Item> = Item & Priority;

export interface OptionalPriority {
  priority?: number;
}

export type OptionalPriorityItem<Item> = Item & Priority;

@Component({
  selector: 'eurogard-priority-sorting-control',
  exportAs: 'eurogardPrioritySortingControl',
  standalone: true,
  imports: [
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    DragDropModule,
    MatButton,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatDivider,
    MatIcon,
    MatIconButton,
    MatListItemTitle,
    NgIf,
    FormsModule,
    MatCardTitle,
    NgClass,
    CdkDragPlaceholder,
    MatListModule,
    MatListItem,
    ToNumberPipe,
    CdkDragHandle,
    AutocompleteFormComponent,
  ],
  templateUrl: './priority-sorting-control.component.html',
  styleUrl: './priority-sorting-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrioritySortingControlComponent),
      multi: true,
    },
  ],
})
export class PrioritySortingControlComponent<Item = unknown> extends ControlValueAccessor {

  public readonly disabled = input(false);

  public readonly value = signal<PriorityItem<Item>[]>([]);

  public readonly groupArray = signal<OptionalPriorityItem<Item>[][]>([]);

  public readonly manualChange = signal(false);

  public readonly hasChanges = computed(() => {
    const groupArray = this.groupArray();
    return this.manualChange() || groupArray.some((group, index) => {
      if (group?.some(user => user.priority == null || user.priority !== index)) {
        return true;
      }
      return false;
    });
  });

  @Input() autocompleteLabel = '';
  @Input() autoCompletePlaceholder = '';

  public readonly newItem = signal<{ index: number, item: OptionalPriorityItem<Item> } | any>(null);

  constructor() {
    super();
    effect(() => {
      const value = this.value();
      this.manualChange.set(false);
      let groupArray: OptionalPriorityItem<Item>[][] = [];
      for (const val of value) {
        const group = groupArray[val.priority] ??= [];
        group.push(structuredClone(val));
      }
      groupArray = groupArray.filter(group => group?.length);
      groupArray.forEach((group, index) => {
        group.forEach(item => item.priority = index);
      });
      this.groupArray.set(groupArray);
    }, {allowSignalWrites: true});
    effect(() => {
      const newItem = this.newItem();
      if (!newItem || !newItem.item) {
        return;
      }
      this.groupArray.update(groupArray => {
        groupArray = structuredClone(groupArray);
        if (
          groupArray[newItem.index] != null &&
          !groupArray.some(group => group.findIndex(item => (item as any).uuid === (newItem.item as any).uuid) !== -1)
        ) {
          groupArray[newItem.index].push({ ...newItem.item });
        }
        return groupArray;
      });
    }, {allowSignalWrites: true});
  }

  @Input()
  toDisplay = (item: OptionalPriorityItem<Item>) => {
    if ('name' in item) {
      return item.name;
    }
    return JSON.stringify(item);
  };

  @Input()
  trackBy = (item: OptionalPriorityItem<Item>) => {
    if (hasIdentifierProperty(item)) {
      return getIdentifierPropertyValue(item);
    }
    return item;
  };

  writeValue(value: PriorityItem<Item>[] | null): void {
    this.value.set(structuredClone(value ?? []));
  }

  addPriority() {
    this.manualChange.set(true);
    this.groupArray.update(groupArray => {
      groupArray = structuredClone(groupArray);
      groupArray.push([]);
      return groupArray;
    });
  }

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.groupArray.update(groupArray => structuredClone(groupArray));
  }

  revertChanges() {
    this.writeValue(this.value());
  }

  saveChanges() {
    let groupArray = this.groupArray();
    groupArray = groupArray.filter(group => group?.length);
    groupArray.forEach((group, index) => {
      group.forEach(item => item.priority = index);
    });
    let list: Array<PriorityItem<Item>> = [];
    for (const [ index, items ] of groupArray.entries()) {
      for (const item of items) {
        list.push({...item, priority: Number(index)});
      }
    }
    list = list.sort((a, b) => a.priority - b.priority);
    this.onChange?.(list);
    // call the write value to ensure that the changes to the priority are reflected in the view
    this.writeValue(list);
  }

  removeItem(groupIndex: number, itemIndex: number) {
    this.manualChange.set(true);
    this.groupArray.update(groupArray => {
      groupArray = structuredClone(groupArray);
      groupArray[groupIndex].splice(itemIndex, 1);
      return groupArray;
    });
  }

  removePriority(index: number) {
    this.manualChange.set(true);
    this.groupArray.update(groupArray => {
      const items = groupArray[index];
      if (items) {
        const newIndex = index === 0 ? 1 : index - 1;
        groupArray[newIndex] ??= [];
        const newArray = groupArray[newIndex] ??= [];
        newArray.push(...items);
      }
      groupArray.splice(index, 1);
      return groupArray;
    });
  }

  compareTo(a: OptionalPriorityItem<Item>, b: OptionalPriorityItem<Item>) {
    if (hasIdentifierProperty(a) && hasIdentifierProperty(b)) {
      return getIdentifierPropertyValue(a) === getIdentifierPropertyValue(b);
    }
    return equals(a, b);
  }

}
