import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import { NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListParameter } from '../parameters/notification-accordion-subscribed-machines-controller-get-autocomplete-machine-option-list.parameter';
import { NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListResponse } from '../responses/notification-accordion-subscribed-machines-controller-get-autocomplete-machine-option-list.response';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-feature-user',
  operationId:
    'NotificationAccordionSubscribedMachinesController_getAutocompleteMachineOptionList',
  operation: `{
  "operationId": "NotificationAccordionSubscribedMachinesController_getAutocompleteMachineOptionList",
  "parameters": [
    {
      "name": "uuid",
      "required": true,
      "in": "path",
      "schema": {
        "type": "string"
      }
    },
    {
      "name": "search",
      "required": false,
      "in": "query",
      "schema": {
        "type": "string"
      }
    },
    {
      "name": "company",
      "required": true,
      "in": "query",
      "schema": {
        "type": "string"
      }
    }
  ],
  "responses": {
    "200": {
      "content": {
        "application/json": {
          "schema": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationAccordionSubscribedMachinesAutocompleteUserOptionDto"
            }
          }
        }
      }
    }
  },
  "method": "get",
  "path": "/notification-accordion/{uuid}/subscribed-machines/autocomplete-machine"
}`,
})
export class NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListRemoteMethod extends OpenApiRemoteMethod<
  NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListResponse,
  NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListParameter,
  void
> {
  public override call(
    parameters: OpenApiRemoteMethodParameter<
      NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListParameter,
      void
    >
  ): Promise<NotificationAccordionSubscribedMachinesControllerGetAutocompleteMachineOptionListResponse> {
    return super.call(parameters);
  }
}
