import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  UserControllerPasswortResetRemoteMethod,
} from 'open-api-legacy/remote-methods/user-controller-passwort-reset.remote-method';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ControlErrorDirective } from '@rxap/material-form-system';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { IsEqualToDirective } from '@rxap/form-system';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
  selector: 'eurogard-set-user-password-dialog',
  templateUrl: './set-user-password-dialog.component.html',
  styleUrls: [ './set-user-password-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,

    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    IsEqualToDirective,
    MatButtonModule,
    NgIf,
    MatIconModule,
    ControlErrorDirective,
    MatProgressBarModule,
  ],
})
export class SetUserPasswordDialogComponent {
  public password = '';

  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { __rowId: string },
    @Inject(MatDialogRef)
    private readonly matDialogRef: MatDialogRef<any>,
    private readonly setUserPassword: UserControllerPasswortResetRemoteMethod,
  ) {
  }

  public async setNewPassword() {
    const uuid     = this.data.__rowId;
    const password = this.password;
    this.loading   = true;

    await this.setUserPassword.call({
      parameters: {
        uuid,
      },
      requestBody: {
        updateMap: {
          password,
        },
      },
    });

    this.matDialogRef.close();
  }
}
