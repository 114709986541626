import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { DataTriggerControllerGetTriggerTypesParameter } from '../parameters/data-trigger-controller-get-trigger-types.parameter';
import { DataTriggerControllerGetTriggerTypesResponse } from '../responses/data-trigger-controller-get-trigger-types.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'data-trigger-controller-getTriggerTypes',
    operation: '{"operationId":"data-trigger-controller-getTriggerTypes","parameters":[{"name":"abc","in":"path","required":true,"schema":{"type":"integer","format":"int32"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/data-trigger-controller/value-translation/{abc}"}'
  })
export class DataTriggerControllerGetTriggerTypesRemoteMethod extends OpenApiRemoteMethod<DataTriggerControllerGetTriggerTypesResponse, DataTriggerControllerGetTriggerTypesParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<DataTriggerControllerGetTriggerTypesParameter, void>): Promise<DataTriggerControllerGetTriggerTypesResponse> {
    return super.call(parameters);
  }
}
