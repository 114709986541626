import { MediaMatcher } from '@angular/cdk/layout';
import {
  computed,
  effect,
  inject,
  Injectable,
  isDevMode,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDrawerMode } from '@angular/material/sidenav';
import { ConfigService } from '@rxap/config';
import { Observable } from 'rxjs';
import { FooterService } from './footer.service';
import { HeaderService } from './header.service';
import { ObserveCurrentThemeDensity } from 'angular-services/theme/observe-current-theme-density';

@Injectable()
export class LayoutService {

  public readonly opened: WritableSignal<boolean>;
  public readonly mode: WritableSignal<MatDrawerMode>;
  public readonly pinned: WritableSignal<boolean>;
  public readonly collapsable: WritableSignal<boolean>;
  public readonly fixedBottomGap: Signal<number>;
  public readonly fixedTopGap: Signal<number>;
  public readonly currentThemeDensity = toSignal(ObserveCurrentThemeDensity());
  public readonly isMobile: Signal<boolean>;
  public readonly fixedInViewport: WritableSignal<boolean>;
  public readonly collapsed: Signal<boolean>;

  private readonly footerService = inject(FooterService);
  private readonly headerService = inject(HeaderService);
  private readonly config = inject(ConfigService);
  private readonly mediaMatcher = inject(MediaMatcher);

  constructor() {
    const mobileQuery = this.mediaMatcher.matchMedia('(max-width: 959px)');
    this.isMobile = toSignal(new Observable<boolean>(subscriber => {
      mobileQuery.addEventListener('change', (event) => {
        subscriber.next(event.matches);
      });
    }), { initialValue: mobileQuery.matches });

    const initialCollapsable = this.config.get('navigation.collapsable', true);
    const collapsable = initialCollapsable && !this.isMobile();
    const pinned = localStorage.getItem('navigation.pinned') === 'true';
    const mode = this.config.get('navigation.mode', pinned || !collapsable ? 'side' : 'over');
    const opened = this.config.get('navigation.opened', (!collapsable || pinned) && !this.isMobile());
    const fixedInViewport = this.config.get('navigation.fixedInViewport', true);

    if (isDevMode()) {
      console.log({
        initialCollapsable,
        collapsable,
        pinned,
        mode,
        opened,
        fixedInViewport,
      });
    }

    this.opened = signal(opened);
    this.mode = signal(mode);
    this.pinned = signal(pinned);
    this.collapsable = signal(collapsable);
    this.fixedInViewport = signal(fixedInViewport);
    this.collapsed = computed(() => this.collapsable() && !this.opened() && !this.pinned());

    this.fixedBottomGap = computed(() => {
      const footerPortalCount = this.footerService.portalCount();
      const currentThemeDensity = this.currentThemeDensity() ?? 0;
      return footerPortalCount * (currentThemeDensity * 4 + 64);
    });

    this.fixedTopGap = computed(() => {
      const headerPortalCount = this.headerService.portalCount();
      const currentThemeDensity = this.currentThemeDensity() ?? 0;
      return headerPortalCount * (currentThemeDensity * 4 + 64);
    });

    if (initialCollapsable) {
      effect(() => {
        const isMobile = this.isMobile();
        this.collapsable.set(!isMobile);
        if (!isMobile && !this.pinned()) {
          this.opened.set(false);
        }
      }, { allowSignalWrites: true });
    }
    effect(() => {
      if (this.pinned()) {
        this.mode.set('side');
        this.opened.set(true);
      } else {
        this.mode.set('over');
        this.opened.set(false);
      }
    }, { allowSignalWrites: true });

    if (this.pinned()) {
      // mat-side-nav margin-left doesn't apply otherwise
      setTimeout(() => {
        this.togglePinned();
        setTimeout(() => {
          this.togglePinned();
        });
      });
    }
  }

  public toggleOpened() {
    this.opened.update(opened => !opened);
  }

  public togglePinned() {
    this.pinned.update(pinned => !pinned);
    localStorage.setItem('navigation.pinned', this.pinned() ? 'true' : 'false');
  }

  openSidenav() {
    this.opened.set(true);
  }

  closeSidenav() {
    this.opened.set(false);
  }
}
