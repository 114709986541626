import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import { NotificationAccordionSettingsControllerTogglePushNotificationParameter } from '../parameters/notification-accordion-settings-controller-toggle-push-notification.parameter';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-feature-user',
  operationId: 'NotificationAccordionSettingsController_togglePushNotification',
  operation: `{
  "operationId": "NotificationAccordionSettingsController_togglePushNotification",
  "parameters": [
    {
      "name": "uuid",
      "required": true,
      "in": "path",
      "schema": {
        "type": "string"
      }
    }
  ],
  "responses": {
    "200": {}
  },
  "method": "put",
  "path": "/notification-accordion/{uuid}/settings/toggle-push-notification"
}`,
})
export class NotificationAccordionSettingsControllerTogglePushNotificationRemoteMethod extends OpenApiRemoteMethod<
  void,
  NotificationAccordionSettingsControllerTogglePushNotificationParameter,
  void
> {
  public override call(
    parameters: OpenApiRemoteMethodParameter<
      NotificationAccordionSettingsControllerTogglePushNotificationParameter,
      void
    >
  ): Promise<void> {
    return super.call(parameters);
  }
}
