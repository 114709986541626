// rxap-no-index-export
import { INJECTOR, Injector, Optional, Provider } from '@angular/core';
import { AutocompleteForm, IAutocompleteForm } from './autocomplete.form';
import {
  RXAP_FORM_DEFINITION,
  RXAP_FORM_DEFINITION_BUILDER,
  RXAP_FORM_INITIAL_STATE,
  RXAP_FORM_SUBMIT_SUCCESSFUL_METHOD,
  RxapFormBuilder,
} from '@rxap/forms';
import { ToMethod } from '@rxap/pattern';

export const FormProviders: Provider[]          = [ AutocompleteForm ];
export const FormComponentProviders: Provider[] = [ {
  provide: RXAP_FORM_DEFINITION,
  useFactory: FormFactory,
  deps: [ INJECTOR, [ new Optional(), RXAP_FORM_INITIAL_STATE ] ],
},
  {
    provide: RXAP_FORM_SUBMIT_SUCCESSFUL_METHOD,
    useFactory: (form: AutocompleteForm) => ToMethod(() => {
      form.rxapFormGroup.reset();
      form.rxapFormGroup.markAsPristine();
      form.rxapFormGroup.markAsUntouched();
    }),
    deps: [ RXAP_FORM_DEFINITION ],
  },
];
export const FormBuilderProviders: Provider[]   = [ {
  provide: RXAP_FORM_DEFINITION_BUILDER,
  useFactory: FormBuilderFactory,
  deps: [ INJECTOR ],
} ];

export function FormFactory(injector: Injector, state: IAutocompleteForm | null): AutocompleteForm {
  return new RxapFormBuilder<IAutocompleteForm>(AutocompleteForm, injector).build(state ?? {});
}

function FormBuilderFactory(injector: Injector): RxapFormBuilder<IAutocompleteForm> {
  return new RxapFormBuilder<IAutocompleteForm>(AutocompleteForm, injector);
}
