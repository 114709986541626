<div class="flex flex-col gap-4">
  <div cdkDropListGroup class="flex flex-col gap-1" cdkDropList [cdkDropListData]="groupArray()" (cdkDropListDropped)="drop($event)">
    @for (group of groupArray(); let groupIndex = $index; track $index) {
      <mat-card cdkDrag [cdkDragDisabled]="disabled()" [cdkDragData]="group">
        <mat-card-header>
          <mat-card-title>
            <div class="flex items-center">
              <mat-icon cdkDragHandle class="drag-indicator mr-3">drag_indicator</mat-icon>
              <div>
                <span i18n>Priority Group</span> {{ groupIndex }}
              </div>
              <button type="button" (click)="removePriority(groupIndex)"
                      [disabled]="disabled()"
                      color="warn"
                      mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list cdkDropList [cdkDropListData]="group" (cdkDropListDropped)="drop($event)">
            @for (item of group; let itemIndex = $index; track trackBy(item)) {
              <mat-list-item
                class="block"
                [ngClass]="{
                 '!bg-amber-500/25': item.priority !== undefined && item.priority >= 0 &&  item.priority !== (groupIndex | toNumber),
                 '!bg-green-500/25': item.priority < 0 || item.priority === undefined,
                }"
                cdkDrag
                [cdkDragDisabled]="disabled()"
                [cdkDragData]="item"
              >
                <div class="flex items-center" matListItemTitle>
                  <mat-icon cdkDragHandle class="drag-indicator ml-4 mr-4">drag_indicator</mat-icon>
                  <div class="flex flex-col">
                    <div class="flex items-center">
                      <span>{{ toDisplay(item) }}</span>
                      <button type="button" (click)="removeItem(groupIndex, itemIndex)"
                              [disabled]="disabled()"
                              color="warn"
                              mat-icon-button>
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <div class="mb-2" *ngIf="item.priority !== undefined && item.priority >= 0 &&  item.priority !== (groupIndex | toNumber)">
                      <span i18n>Moved from Priority Group {{ item.priority }} to Priority Group {{ groupIndex }}</span>
                    </div>
                    <div class="mb-2" *ngIf="item.priority < 0 || item.priority === undefined">
                      <span i18n>Newly added</span>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-100/25 border border-dotted border-2 transition-all h-16" *cdkDragPlaceholder></div>
              </mat-list-item>
            }
            <div class="w-[400px] mt-4">
              <eurogard-autocomplete-form
                (item)="newItem.set({ index: groupIndex, item: $event })"
                i18n-label
                i18n-placeholder
                [label]="autocompleteLabel"
                [placeholder]="autoCompletePlaceholder"
              ></eurogard-autocomplete-form>
            </div>
          </mat-list>
        </mat-card-content>
      </mat-card>
    }
  </div>
  <mat-divider></mat-divider>
  <div class="flex flex-row items-center gap-4">
    <button (click)="addPriority()" [disabled]="disabled()" color="accent" mat-raised-button type="button">
      <ng-container i18n>Add a new priority group</ng-container>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="flex flex-row items-center gap-4">
    <button (click)="saveChanges()" [disabled]="disabled() || !hasChanges()" color="primary" mat-raised-button
            type="button">
      <ng-container i18n>Save Changes</ng-container>
    </button>
    <button (click)="revertChanges()" [disabled]="disabled() || !hasChanges()" color="warn" mat-stroked-button
            type="button">
      <ng-container i18n>Revert Changes</ng-container>
    </button>
  </div>
</div>
