import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { isDefined } from '@rxap/rxjs';

@Pipe({
  name: 'skipWhileNil',
  standalone: true,
})
export class SkipWhileNilPipe implements PipeTransform {

  transform<T>(source: Observable<T | null | undefined>): Observable<T> {
    return source.pipe(isDefined());
  }

}


