import { RxapDataSource } from '@rxap/data-source';
import { Injectable, inject } from '@angular/core';
import { NotificationAccordionSettingsControllerGetByIdResponse } from 'open-api/responses/notification-accordion-settings-controller-get-by-id.response';
import { NotificationAccordionSettingsControllerGetByIdRemoteMethod } from 'open-api/remote-methods/notification-accordion-settings-controller-get-by-id.remote-method';
import { PanelAccordionDataSource } from '@rxap/data-source/accordion';

@Injectable()
@RxapDataSource('settings-panel')
export class SettingsPanelDataSource extends PanelAccordionDataSource<NotificationAccordionSettingsControllerGetByIdResponse> {
  protected override readonly method = inject(NotificationAccordionSettingsControllerGetByIdRemoteMethod);
}
