import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampFromLDAP',
  standalone: true,
})
export class TimestampFromLDAPPipe implements PipeTransform {

  public transform(value: number): number {
    const timestampMs = Number(value) / 10000;
    const epochStart = Date.UTC(1601, 0, 1);
    const utcDate = new Date(epochStart + timestampMs);
    return utcDate.getTime();
  }

}
