import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import { NotificationAccordionSettingsControllerToggleEmailNotificationParameter } from '../parameters/notification-accordion-settings-controller-toggle-email-notification.parameter';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-feature-user',
  operationId:
    'NotificationAccordionSettingsController_toggleEmailNotification',
  operation: `{
  "operationId": "NotificationAccordionSettingsController_toggleEmailNotification",
  "parameters": [
    {
      "name": "uuid",
      "required": true,
      "in": "path",
      "schema": {
        "type": "string"
      }
    }
  ],
  "responses": {
    "200": {}
  },
  "method": "put",
  "path": "/notification-accordion/{uuid}/settings/toggle-email-notification"
}`,
})
export class NotificationAccordionSettingsControllerToggleEmailNotificationRemoteMethod extends OpenApiRemoteMethod<
  void,
  NotificationAccordionSettingsControllerToggleEmailNotificationParameter,
  void
> {
  public override call(
    parameters: OpenApiRemoteMethodParameter<
      NotificationAccordionSettingsControllerToggleEmailNotificationParameter,
      void
    >
  ): Promise<void> {
    return super.call(parameters);
  }
}
