<h2 i18n mat-dialog-title>Set User Password</h2>
<mat-dialog-content>
  <div class="flex flex-col">
    <p i18n>
      Set the user password to the new password. Make sure you notify the user
      of the change.
    </p>

    <div
      class="flex-grow flex flex-row gap-[16px]"
      data-cy="form.user-create.password.container"
      style="padding: 32px 0"
    >
      <mat-form-field class="flex-grow" data-cy="form.user-create.password">
        <mat-label i18n>Password</mat-label>
        <input
          #passwordInput
          #passwordInputModel="ngModel"
          [(ngModel)]="password"
          [required]="true"
          [rxapIsEqualTo]="passwordValidateInput.value"
          i18n-placeholder
          matInput
          maxLength="30"
          minLength="8"
          pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&+=]).{8,30}$"
          placeholder="Enter password"
          type="password"
        />
        <button
          (click)="
            passwordInput.type === 'password'
              ? (passwordInput.type = 'text')
              : (passwordInput.type = 'password')
          "
          mat-icon-button
          matPrefix
          tabindex="-1"
          type="button"
        >
          <mat-icon *ngIf="passwordInput.type === 'password'">
            visibility
          </mat-icon>
          <mat-icon *ngIf="passwordInput.type === 'text'">
            visibility_off
          </mat-icon>
        </button>
        <mat-error
          *rxapControlError="let error; key: 'isEqualTo'"
          data-cy="error.isEqualTo"
        >
          <ng-container i18n>The passwords are not equal</ng-container>
        </mat-error>
        <mat-error
          *rxapControlError="let error; key: 'pattern'"
          data-cy="error.pattern"
        >
          <ng-container i18n
          >The password must contain at least a lower letter, upper letter,
            number and special character
          </ng-container>
        </mat-error>
        <mat-error
          *rxapControlError="let error; key: 'minlength'"
          data-cy="error.minlength"
        >
          <ng-container i18n
          >The password has to be at least 8 characters long
          </ng-container>
        </mat-error>
        <mat-error
          *rxapControlError="let error; key: 'maxlength'"
          data-cy="error.maxlength"
        >
          <ng-container i18n
          >The password cannot be longer than 30 characters
          </ng-container>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="flex-grow"
        data-cy="form.user-create.password-repeat"
      >
        <mat-label i18n>Password Repeat</mat-label>
        <input
          #passwordValidateInput
          [required]="true"
          data-cy="form.user-create.password.repeat"
          i18n-placeholder
          matInput
          placeholder="Enter password-repeat"
          type="password"
        />
        <button
          (click)="
            passwordValidateInput.type === 'password'
              ? (passwordValidateInput.type = 'text')
              : (passwordValidateInput.type = 'password')
          "
          mat-icon-button
          matPrefix
          tabindex="-1"
          type="button"
        >
          <mat-icon *ngIf="passwordInput.type === 'password'">
            visibility
          </mat-icon>
          <mat-icon *ngIf="passwordInput.type === 'text'">
            visibility_off
          </mat-icon>
        </button>
      </mat-form-field>
    </div>

    <mat-progress-bar
      *ngIf="loading"
      class="progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button i18n mat-button mat-dialog-close>Cancel</button>
  <button
    (click)="setNewPassword()"
    [disabled]="passwordInputModel.invalid || !password"
    color="primary"
    i18n
    mat-raised-button
  >
    Set new password
  </button>
</mat-dialog-actions>
