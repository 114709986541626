import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import { NotificationAccordionSettingsControllerGetByIdParameter } from '../parameters/notification-accordion-settings-controller-get-by-id.parameter';
import { NotificationAccordionSettingsControllerGetByIdResponse } from '../responses/notification-accordion-settings-controller-get-by-id.response';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-feature-user',
  operationId: 'NotificationAccordionSettingsController_getById',
  operation: `{
  "operationId": "NotificationAccordionSettingsController_getById",
  "parameters": [
    {
      "name": "uuid",
      "required": true,
      "in": "path",
      "schema": {
        "type": "string"
      }
    }
  ],
  "responses": {
    "200": {
      "content": {
        "application/json": {
          "schema": {
            "$ref": "#/components/schemas/NotificationAccordionSettingsDto"
          }
        }
      }
    }
  },
  "method": "get",
  "path": "/notification-accordion/{uuid}/settings"
}`,
})
export class NotificationAccordionSettingsControllerGetByIdRemoteMethod extends OpenApiRemoteMethod<
  NotificationAccordionSettingsControllerGetByIdResponse,
  NotificationAccordionSettingsControllerGetByIdParameter,
  void
> {
  public override call(
    parameters: OpenApiRemoteMethodParameter<
      NotificationAccordionSettingsControllerGetByIdParameter,
      void
    >
  ): Promise<NotificationAccordionSettingsControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
