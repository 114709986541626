import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';
import { DataSourceDirective } from '@rxap/data-source/directive';
import { DataSourceErrorComponent } from '@rxap/data-source';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelContent,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatProgressBar } from '@angular/material/progress-bar';
import { PersistentAccordionDirective } from '@rxap/material-directives/expansion';
import {
  SettingsPanelComponent
} from 'angular-components/settings-panel/settings-panel.component';
import {
  SubscribedMachinesPanelComponent
} from 'angular-components/subscribed-machines-panel/subscribed-machines-panel.component';
import {
  UserSettingsAccordionDataSource
} from 'angular-components/layout/default-header/user-profile-icon/user-settings/user-settings-accordion.data-source';
import { ACCORDION_DATA_SOURCE } from '@rxap/data-source/accordion';
import { MatListModule } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import {
  SetUserPasswordDialogComponent
} from 'angular-components/set-user-password-dialog/set-user-password-dialog.component';

@Component({
  selector: 'eurogard-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: [ './user-settings.component.scss' ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatDialogModule,
    AsyncPipe,
    DataSourceDirective,
    DataSourceErrorComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelContent,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatProgressBar,
    PersistentAccordionDirective,
    SettingsPanelComponent,
    SubscribedMachinesPanelComponent,
    MatListModule,
    MatIcon,
  ],
  providers: [
    UserSettingsAccordionDataSource,
    {
      provide: ACCORDION_DATA_SOURCE,
      useExisting: UserSettingsAccordionDataSource,
    },
  ],
})
export class UserSettingsComponent {
  public readonly accordionDataSource = inject(UserSettingsAccordionDataSource);
  private readonly dialog = inject(MatDialog);

  public async openResetPasswordDialog() {
    this.dialog.open(SetUserPasswordDialogComponent, {
      data: { __rowId: this.accordionDataSource.user?.uuid },
    });
  }

}
