import { RxapDataSource } from '@rxap/data-source';
import { Injectable, inject } from '@angular/core';
import { NotificationAccordionControllerGetByIdResponse } from 'open-api-service-feature-user/responses/notification-accordion-controller-get-by-id.response';
import { NotificationAccordionControllerGetByIdRemoteMethod } from 'open-api-service-feature-user/remote-methods/notification-accordion-controller-get-by-id.remote-method';
import { AccordionDataSource } from '@rxap/data-source/accordion';
import { NotificationAccordionControllerGetByIdParameter } from 'open-api-service-feature-user/parameters/notification-accordion-controller-get-by-id.parameter';
import { OpenApiRemoteMethodParameter } from '@rxap/open-api/remote-method';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { UserControllerMeGUIRemoteMethod } from 'open-api-legacy/remote-methods/user-controller-me-gui.remote-method';
import { UserControllerMeGUIResponse } from 'open-api-legacy/responses/user-controller-me-gui.response';

@Injectable()
@RxapDataSource('user-settings-accordion')
export class UserSettingsAccordionDataSource extends AccordionDataSource<NotificationAccordionControllerGetByIdResponse, OpenApiRemoteMethodParameter<NotificationAccordionControllerGetByIdParameter, void>> {
  protected readonly route = inject(ActivatedRoute);
  protected override readonly method = inject(NotificationAccordionControllerGetByIdRemoteMethod);
  private readonly getCurrentUser = inject(UserControllerMeGUIRemoteMethod);
  public user?: UserControllerMeGUIResponse;

  getParameters() {
    return from(this.getCurrentUser.call()).pipe(
      tap(user => {
        this.user = user;
      }),
      map(user => ({ parameters: { uuid: user.uuid! } }))
    );
  }
}
