import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { DataDefinitionGuiControllerGetByUuidParameter } from '../parameters/data-definition-gui-controller-get-by-uuid.parameter';
import { DataDefinitionGuiControllerGetByUuidResponse } from '../responses/data-definition-gui-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'data-definition-gui-controller-getByUuid',
    operation: '{"operationId":"data-definition-gui-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"deletedAt":{"type":"integer","format":"int64"},"uuid":{"type":"string","format":"uuid"},"name":{"type":"string"},"dataDefinitionKeyItem":{"$ref":"#/components/schemas/DataDefinitionKeyItemDetails"},"dataDefinitionType":{"type":"string","enum":["2"]},"share":{"type":"boolean"},"readLevel":{"type":"integer","format":"int32"},"writeLevel":{"type":"integer","format":"int32"},"machineTypeDefinitionUuid":{"type":"string","format":"uuid"},"machineTypeDefinitionName":{"type":"string"},"physicalUnit":{"$ref":"#/components/schemas/PhysicalUnit"},"dataTrigger":{"$ref":"#/components/schemas/DataTrigger"},"messageDefinition":{"$ref":"#/components/schemas/MessageDefinitionDetails"},"connectionType":{"type":"string","enum":["4"]},"connection":{"$ref":"#/components/schemas/DataDefinitionConnection"},"scaling":{"$ref":"#/components/schemas/Scaling"},"messageGroup":{"$ref":"#/components/schemas/MessageGroup"},"messageDefinitionKeyItemUuidList":{"type":"array","items":{"type":"string","format":"uuid"}},"destination":{"type":"integer","format":"int64"},"dataId":{"type":"string","format":"uuid"},"visibility":{"type":"string","enum":[]},"isFavorite":{"type":"boolean"},"default":{"$ref":"#/components/schemas/DataDefinition"},"dataDefinition":{"$ref":"#/components/schemas/DataDefinition"},"archived":{"type":"boolean"},"machineTypeDefinition":{"$ref":"#/components/schemas/MachineTypeDefinition"},"__updatedBy":{"type":"string"},"__updatedAt":{"type":"integer","format":"int64"},"__removedAt":{"type":"integer","format":"int64"},"__archived":{"type":"boolean"}}}}}}},"method":"get","path":"/data-definition-gui-controller/get/{uuid}"}'
  })
export class DataDefinitionGuiControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<DataDefinitionGuiControllerGetByUuidResponse, DataDefinitionGuiControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<DataDefinitionGuiControllerGetByUuidParameter, void>): Promise<DataDefinitionGuiControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}
