import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'significantDigits',
  standalone: true,
})
export class SignificantDigitsPipe implements PipeTransform {

  transform(value: number | string, significantDigitsBefore?: number | string, significantDigitsAfter: number | string = 2): string {
    let beforeAsNumber: number | undefined, afterAsNumber: number | undefined;
    if (significantDigitsBefore === null || significantDigitsBefore === '') {
      beforeAsNumber = undefined;
    } else {
      beforeAsNumber = Number(significantDigitsBefore);
    }
    if (significantDigitsAfter === null || significantDigitsAfter === '') {
      afterAsNumber = 2;
    } else {
      afterAsNumber = Number(significantDigitsAfter);
    }

    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (isNaN(value)) {
      return value.toString(); // not a numeric value
    }

    const absValue = Math.abs(value);
    const digitsCount = Math.floor(Math.log10(absValue)) + 1;

    if (beforeAsNumber !== undefined && !isNaN(beforeAsNumber)) {
      if (digitsCount <= beforeAsNumber) {
        return parseFloat(value.toFixed(afterAsNumber)).toString();
      } else {
        const factor = Math.pow(10, digitsCount - beforeAsNumber);
        const scaledValue = Math.round(value / factor);
        return `${scaledValue} × 10<sup>${digitsCount - beforeAsNumber}</sup>`;
      }
    }

    return parseFloat(value.toFixed(afterAsNumber)).toString();
  }
}
