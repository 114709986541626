import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upgradeToHttps',
  standalone: true,
})
export class UpgradeToHttpsPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace('http://', 'https://');
  }

}
