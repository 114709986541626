import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { MachineControllerGetByUuidParameter } from '../parameters/machine-controller-get-by-uuid.parameter';
import { MachineControllerGetByUuidResponse } from '../responses/machine-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'machine-controller-getByUuid',
    operation: '{"operationId":"machine-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"uuid":{"type":"string","format":"uuid"},"name":{"type":"string"},"thing":{"$ref":"#/components/schemas/Thing"},"machineTypeDefinition":{"$ref":"#/components/schemas/MachineTypeDefinition"},"metaDataList":{"type":"array","items":{"$ref":"#/components/schemas/MetaData"}},"machineConnectionMappings":{"type":"array","items":{"$ref":"#/components/schemas/MachineConnectionMapping"}},"lastConnection":{"$ref":"#/components/schemas/LastConnection"},"company":{"$ref":"#/components/schemas/Company"},"location":{"$ref":"#/components/schemas/Company"},"alarmConfiguration":{"$ref":"#/components/schemas/AlarmConfiguration"},"isOnline":{"type":"boolean"},"onlineInterval":{"type":"integer","format":"int64"},"thingUuid":{"type":"string","format":"uuid"},"companyName":{"type":"string"},"thingName":{"type":"string"},"machineTypeDefinitionName":{"type":"string"},"machineTypeDefinitionUuid":{"type":"string","format":"uuid"},"connection":{"type":"array","deprecated":true,"items":{"$ref":"#/components/schemas/Connection"}},"removedAt":{"type":"integer","format":"int64"},"__updatedBy":{"type":"string"},"__updatedAt":{"type":"integer","format":"int64"},"__removedAt":{"type":"integer","format":"int64"},"__archived":{"type":"boolean"},"updatedAt":{"type":"integer","format":"int64"},"deletedAt":{"type":"integer","format":"int64"}}}}}}},"method":"get","path":"/machine-controller/get/{uuid}"}'
  })
export class MachineControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<MachineControllerGetByUuidResponse, MachineControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<MachineControllerGetByUuidParameter, void>): Promise<MachineControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}
