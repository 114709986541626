import { Pipe, PipeTransform } from '@angular/core';
import { MachineControllerGetByUuidResponse } from 'open-api-legacy/responses/machine-controller-get-by-uuid.response';
import {
  MachineControllerGetByUuidRemoteMethod,
} from 'open-api-legacy/remote-methods/machine-controller-get-by-uuid.remote-method';

@Pipe({
  name: 'machine',
  standalone: true,
})
export class MachinePipe implements PipeTransform {

  constructor(
    private readonly getMachine: MachineControllerGetByUuidRemoteMethod,
  ) {
  }

  public async transform(uuid: string | null): Promise<null | MachineControllerGetByUuidResponse> {
    if (uuid) {
      return this.getMachine.call({parameters: {uuid}});
    }
    return null;
  }

}


