import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { DataSourceDirective } from '@rxap/data-source/directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingsPanelDataSource } from './settings-panel.data-source';
import {
  BoxedSlideToggleControlComponent,
} from 'angular-controls/boxed-slide-toggle-control/boxed-slide-toggle-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogService } from 'angular-components/confirm-dialog/confirm-dialog.service';
import {
  NotificationAccordionSettingsControllerToggleAlarmingRemoteMethod,
} from 'open-api/remote-methods/notification-accordion-settings-controller-toggle-alarming.remote-method';
import {
  NotificationAccordionSettingsControllerTogglePushNotificationRemoteMethod,
} from 'open-api/remote-methods/notification-accordion-settings-controller-toggle-push-notification.remote-method';
import {
  NotificationAccordionSettingsControllerToggleEmailNotificationRemoteMethod,
} from 'open-api/remote-methods/notification-accordion-settings-controller-toggle-email-notification.remote-method';
import { MatFormField } from '@angular/material/form-field';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {
  NotificationAccordionSettingsControllerSetTelegramNotificationRemoteMethod
} from 'open-api/remote-methods/notification-accordion-settings-controller-set-telegram-notification.remote-method';
import {
  NotificationAccordionSettingsControllerSendTestNotificationRemoteMethod
} from 'open-api/remote-methods/notification-accordion-settings-controller-send-test-notification.remote-method';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UserControllerSendRandomMessageRemoteMethod
} from 'open-api-legacy/remote-methods/user-controller-send-random-message.remote-method';
import {
  UserControllerSendRandomMailRemoteMethod
} from 'open-api-legacy/remote-methods/user-controller-send-random-mail.remote-method';
import { DataSourceErrorComponent } from '@rxap/data-source';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'eurogard-settings-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './settings-panel.component.html',
  styleUrls: [ './settings-panel.component.scss' ],
  imports: [
    DataSourceDirective,
    MatProgressBarModule,
    BoxedSlideToggleControlComponent,
    FormsModule,
    MatDialogModule,
    MatFormField,
    MatInputModule,
    ReactiveFormsModule,
    MatButton,
    DataSourceErrorComponent,
    NgIf,
  ],
  providers: [ ConfirmDialogService,
    SettingsPanelDataSource ],
})
export class SettingsPanelComponent {
  public readonly panelDataSource = inject(SettingsPanelDataSource);
  public readonly updating = signal(false);
  public readonly loading = computed(() => this.updating() || this.panelDataSource.loading());
  public readonly data = toSignal(this.panelDataSource.connect({id: 'to-signal-settings-panel'}));

  protected readonly useTelegramAlarming = signal(false);
  protected readonly telegramAPIToken = signal('');
  protected readonly telegramChatId = signal('');

  private readonly snackBar = inject(MatSnackBar);

  private readonly toggleAlarmingMethod          = inject(
    NotificationAccordionSettingsControllerToggleAlarmingRemoteMethod);
  private readonly togglePushNotificationMethod  = inject(
    NotificationAccordionSettingsControllerTogglePushNotificationRemoteMethod);
  private readonly toggleEmailNotificationMethod = inject(
    NotificationAccordionSettingsControllerToggleEmailNotificationRemoteMethod);
  private readonly setTelegramNotification = inject(
    NotificationAccordionSettingsControllerSetTelegramNotificationRemoteMethod
  );
  private readonly testPushNotificationRemoteMethod = inject(
    UserControllerSendRandomMessageRemoteMethod
  );
  private readonly testEmailNotificationRemoteMethod = inject(
    UserControllerSendRandomMailRemoteMethod
  );
  private readonly testTelegramNotificationRemoteMethod = inject(
    NotificationAccordionSettingsControllerSendTestNotificationRemoteMethod
  );

  private readonly confirmDialogService = inject(ConfirmDialogService);

  protected get hasCurrentCredentials() {
    return this.data()!.telegramChatId != null && this.data()!.telegramChatId != null;
  }

  constructor() {
    effect(() => {
      const data = this.data();
      if (data) {
        this.useTelegramAlarming.set(data.telegramNotificationEnabled);
        this.telegramChatId.set(data.telegramChatId);
        this.telegramAPIToken.set('');
      }
    }, { allowSignalWrites: true });
  }

  protected get saveButtonLabel() {
    if (!this.hasCurrentCredentials) {
      return $localize`Save new credentials`;
    } else {
      if (this.telegramAPIToken() === '') {
        return $localize`Update Chat ID`;
      } else {
        return $localize`Override current credentials`;
      }
    }
  }

  public async toggleAlarming(newValue: boolean, alarmingToggle: BoxedSlideToggleControlComponent) {
    this.updating.set(true);
    const confirmed = await this.confirmDialogService.open(
      $localize`Are you sure you want to ${ newValue ? 'enable' : 'disable' } alarming?`
    );
    if (confirmed) {
      await this.toggleAlarmingMethod.call({
        parameters: {
          uuid: this.data()!.uuid,
        },
      });
      this.panelDataSource.refresh();
    } else {
      alarmingToggle.writeValue(!newValue);
    }
    this.updating.set(false);
  }

  public async toggleEmailNotification(newValue: boolean, emailNotificationsToggle: BoxedSlideToggleControlComponent) {
    this.updating.set(true);
    const confirmed = await this.confirmDialogService.open($localize`Are you sure you want to ${ newValue ?
      'enable' :
      'disable' } email notification?`);
    if (confirmed) {
      await this.toggleEmailNotificationMethod.call({
        parameters: {
          uuid: this.data()!.uuid,
        },
      });
      this.panelDataSource.refresh();
    } else {
      emailNotificationsToggle.writeValue(!newValue);
    }
    this.updating.set(false);
  }

  public async togglePushNotification(newValue: boolean, pushNotificationsToggle: BoxedSlideToggleControlComponent) {
    this.updating.set(true);
    const confirmed = await this.confirmDialogService.open($localize`Are you sure you want to ${ newValue ?
      'enable' :
      'disable' } push notification?`);
    if (confirmed) {
      await this.togglePushNotificationMethod.call({
        parameters: {
          uuid: this.data()!.uuid,
        },
      });
      this.panelDataSource.refresh();
    } else {
      pushNotificationsToggle.writeValue(!newValue);
    }
    this.updating.set(false);
  }

  protected async toggleTelegramNotification(newValue: boolean, telegramNotificationsToggle: BoxedSlideToggleControlComponent) {
    if (
      newValue && !this.data()!.telegramNotificationEnabled && this.hasCurrentCredentials ||
      !newValue && this.data()!.telegramNotificationEnabled
    ) {
      this.updating.set(true);
      const confirmed = await this.confirmDialogService.open($localize`Are you sure you want to ${ newValue ?
        'enable' :
        'disable' } telegram notifications?`);
      if (confirmed) {
        await this.setTelegramNotification.call({
          parameters: {
            uuid: this.data()!.uuid,
          },
          requestBody: {
            telegramNotificationEnabled: newValue,
          },
        });
        this.panelDataSource.refresh();
      } else {
        telegramNotificationsToggle.writeValue(!newValue);
      }
      this.updating.set(false);
    } else {
      this.useTelegramAlarming.set(newValue);
      telegramNotificationsToggle.writeValue(newValue);
    }
  }

  protected async saveTelegramCredentials() {
    this.updating.set(true);
    const telegramNotificationEnabled = this.useTelegramAlarming();
    const telegramApiToken = telegramNotificationEnabled
      ? this.telegramAPIToken() !== '' ? this.telegramAPIToken() : undefined
      : undefined;
    const telegramChatId = this.telegramChatId() ? this.telegramChatId() : undefined;
    await this.setTelegramNotification.call({
      parameters: {
        uuid: this.data()!.uuid,
      },
      requestBody: {
        telegramNotificationEnabled,
        telegramApiToken,
        telegramChatId,
      },
    });
    this.panelDataSource.refresh();
    this.updating.set(false);
  }

  protected async sendPushTestNotification() {
    this.updating.set(true);
    this.testPushNotificationRemoteMethod
    .call({ parameters: { uuid: this.data()!.uuid } })
    .then(() => {
      this.snackBar.open($localize`Test notification sent`, 'OK', {
        duration: 5000,
      });
    })
    .catch(err => {
      console.error(err);
      this.snackBar.open($localize`Failed to send test notification`, 'OK', {
        duration: 5000,
      });
    })
    .finally(() => this.updating.set(false));
  }

  protected async sendEmailTestNotification() {
    this.updating.set(true);
    this.testEmailNotificationRemoteMethod
    .call({ parameters: { uuid: this.data()!.uuid } })
    .then(() => {
      this.snackBar.open($localize`Test notification sent`, 'OK', {
        duration: 5000,
      });
    })
    .catch(err => {
      console.error(err);
      this.snackBar.open($localize`Failed to send test notification`, 'OK', {
        duration: 5000,
      });
    })
    .finally(() => this.updating.set(false));
  }

  protected async sendTelegramTestNotification() {
    this.updating.set(true);
    this.testTelegramNotificationRemoteMethod
      .call({ parameters: { uuid: this.data()!.uuid } })
      .then(() => {
        this.snackBar.open($localize`Test notification sent`, 'OK', {
          duration: 5000,
        });
      })
      .catch(err => {
        console.error(err);
        this.snackBar.open($localize`Failed to send test notification`, 'OK', {
          duration: 5000,
        });
      })
      .finally(() => this.updating.set(false));
  }

}
