import {
  ChangeDetectionStrategy,
  Component,
  Inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { SantizationPipe } from '@rxap/pipes/santization';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

export interface ConfirmDialogData {
  message: string;
  action?: string;
  title?: string;
  warning?: string;
}

@Component({
  selector: 'eurogard-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [ './confirm-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ MatDialogModule, MatButtonModule, MatIconModule, SantizationPipe, NgIf ],
})
export class ConfirmDialogComponent {

  protected readonly data: ConfirmDialogData;
  protected readonly defaultTitle = $localize`Confirm`;
  protected readonly defaultAction = $localize`Confirm`;


  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmDialogData,
  ) {
    this.data = data;
  }

}
