import { FormType, RxapForm, RxapFormControl, RxapFormGroup, UseFormControl } from '@rxap/forms';
import { Injectable } from '@angular/core';
import {
  AUTOCOMPLETE_ITEM_AUTOCOMPLETE_OPTIONS_METHOD,
  AUTOCOMPLETE_ITEM_AUTOCOMPLETE_RESOLVE_METHOD,
} from './tokens';
import { UseAutocompleteOptionsMethod } from 'autocomplete-table-select';
import { AutocompleteItem } from './types';
import { UseResolveMethod } from '@rxap/form-system';

@RxapForm({
  id: 'autocomplete',
})
@Injectable()
export class AutocompleteForm implements FormType<IAutocompleteForm> {
  public readonly rxapFormGroup!: RxapFormGroup<IAutocompleteForm>;

  @UseAutocompleteOptionsMethod(AUTOCOMPLETE_ITEM_AUTOCOMPLETE_OPTIONS_METHOD)
  @UseResolveMethod(AUTOCOMPLETE_ITEM_AUTOCOMPLETE_RESOLVE_METHOD)
  @UseFormControl()
  public readonly item!: RxapFormControl<AutocompleteItem>;
}

export interface IAutocompleteForm {
  item: AutocompleteItem;
}
