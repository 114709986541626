import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fleetChangeDirection',
  standalone: true,
})
export class FleetChangeDirectionPipe implements PipeTransform {

  public transform(state: number | null): string {
    switch (state) {
      case 0:
        return 'From router';
      case 1:
        return 'From cloud';
      default:
        return 'Unknown';
    }
  }

}


