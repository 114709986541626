<mat-progress-bar *ngIf="loading()" class="py-2" mode="indeterminate"></mat-progress-bar>
<ng-template #errorTemplate let-error let-refresh="refresh">
  <rxap-data-source-error [error]="error" [refresh]="refresh"></rxap-data-source-error>
</ng-template>
<div *rxapDataSource="let panel from panelDataSource; errorTemplate: errorTemplate" class="flex flex-col gap-4">
  <eurogard-priority-sorting-control #prioritySortingControl="eurogardPrioritySortingControl"
                                     (ngModelChange)="updateSubscriber($event)"
                                     [disabled]="loading()"
                                     [ngModel]="panel.machineList"
                                     [autocompleteLabel]="autocompleteLabel"
                                     [autoCompletePlaceholder]="autoCompletePlaceholder"
  >
  </eurogard-priority-sorting-control>
</div>
