import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { toString } from 'cronstrue';

@Pipe({
  name: 'cronToHumanReadable',
  standalone: true,
})
export class CronToHumanReadablePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID)
    private readonly localId: string,
  ) {
  }

  transform(cron: string): string {
    return toString(cron, {
      throwExceptionOnParseError: false,
      locale: this.localId.replace(/-.*$/, ''),
    });
  }

}


