import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import { NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsParameter } from '../parameters/notification-accordion-subscribed-machines-controller-update-subscriptions.parameter';
import { NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsRequestBody } from '../request-bodies/notification-accordion-subscribed-machines-controller-update-subscriptions.request-body';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-feature-user',
  operationId:
    'NotificationAccordionSubscribedMachinesController_updateSubscriptions',
  operation: `{
  "operationId": "NotificationAccordionSubscribedMachinesController_updateSubscriptions",
  "parameters": [
    {
      "name": "uuid",
      "required": true,
      "in": "path",
      "schema": {
        "type": "string"
      }
    }
  ],
  "requestBody": {
    "required": true,
    "content": {
      "application/json": {
        "schema": {
          "$ref": "#/components/schemas/NotificationAccordionSubscribedMachinesDto"
        }
      }
    }
  },
  "responses": {
    "200": {}
  },
  "method": "put",
  "path": "/notification-accordion/{uuid}/subscribed-machines"
}`,
})
export class NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsRemoteMethod extends OpenApiRemoteMethod<
  void,
  NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsParameter,
  NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsRequestBody
> {
  public override call(
    parameters: OpenApiRemoteMethodParameter<
      NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsParameter,
      NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsRequestBody
    >
  ): Promise<void> {
    return super.call(parameters);
  }
}
