import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import { NotificationAccordionSubscribedMachinesControllerGetByIdParameter } from '../parameters/notification-accordion-subscribed-machines-controller-get-by-id.parameter';
import { NotificationAccordionSubscribedMachinesControllerGetByIdResponse } from '../responses/notification-accordion-subscribed-machines-controller-get-by-id.response';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-feature-user',
  operationId: 'NotificationAccordionSubscribedMachinesController_getById',
  operation: `{
  "operationId": "NotificationAccordionSubscribedMachinesController_getById",
  "parameters": [
    {
      "name": "uuid",
      "required": true,
      "in": "path",
      "schema": {
        "type": "string"
      }
    }
  ],
  "responses": {
    "200": {
      "content": {
        "application/json": {
          "schema": {
            "$ref": "#/components/schemas/NotificationAccordionSubscribedMachinesDto"
          }
        }
      }
    }
  },
  "method": "get",
  "path": "/notification-accordion/{uuid}/subscribed-machines"
}`,
})
export class NotificationAccordionSubscribedMachinesControllerGetByIdRemoteMethod extends OpenApiRemoteMethod<
  NotificationAccordionSubscribedMachinesControllerGetByIdResponse,
  NotificationAccordionSubscribedMachinesControllerGetByIdParameter,
  void
> {
  public override call(
    parameters: OpenApiRemoteMethodParameter<
      NotificationAccordionSubscribedMachinesControllerGetByIdParameter,
      void
    >
  ): Promise<NotificationAccordionSubscribedMachinesControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
