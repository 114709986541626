import { NgModule, Pipe, PipeTransform } from '@angular/core';
import {
  DataTriggerControllerGetTriggerTypesRemoteMethod,
} from 'open-api-legacy/remote-methods/data-trigger-controller-get-trigger-types.remote-method';

@Pipe({
  name: 'dataTriggerValueTranslate',
  standalone: true,
})
export class DataTriggerValueTranslatePipe implements PipeTransform {
  constructor(
    private readonly getTriggerTypes: DataTriggerControllerGetTriggerTypesRemoteMethod,
  ) {
  }

  public async transform(value: string, type?: number | string): Promise<string> {
    const optionsMap = await this.getTriggerTypes.call({parameters: {abc: Number(type)}});
    return optionsMap[value];
  }
}


