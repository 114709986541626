import { Pipe, PipeTransform } from '@angular/core';
import {
  FleetControllerGetRepoStatesResponse
} from 'open-api-legacy/responses/fleet-controller-get-repo-states.response';

@Pipe({
  name: 'fleetState',
  standalone: true,
})
export class FleetStatePipe implements PipeTransform {

  public transform(state: number | null, stateTypes: FleetControllerGetRepoStatesResponse | undefined) {
    if (!stateTypes) return 'Unknown';

    const stateType = stateTypes.find(e => Number(e.id) === state);
    if (stateType == null) return "Unknown";
    return stateType.name;
  }
}


