import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
  signal,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@rxap/forms';
import { NgClass } from '@angular/common';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'eurogard-boxed-slide-toggle-control',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    MatSlideToggleModule,
  ],
  templateUrl: './boxed-slide-toggle-control.component.html',
  styleUrl: './boxed-slide-toggle-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoxedSlideToggleControlComponent),
      multi: true,
    },
  ],
})
export class BoxedSlideToggleControlComponent extends ControlValueAccessor {

  value = signal(false);

  disabled = input(false);

  @ViewChild(MatSlideToggle) slideToggle!: MatSlideToggle;

  override writeValue(value: boolean | null) {
    console.log('writeValue', value);
    this.value.set(value ?? false);
    if (this.slideToggle !== undefined) {
      this.slideToggle.checked = this.value();
    }
  }

}
