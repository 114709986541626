import { AutocompleteOptionsFromMethodDirectiveParameters } from 'autocomplete-table-select';
import { OpenApiRemoteMethodParameter } from '@rxap/open-api/remote-method';
import { ControlOption, ControlOptions } from '@rxap/utilities';

export function AutocompleteOptionsMapperFactory(propertyKey = 'name') {
  return (search?: string | null) => {
    if (search) {
      return `${ propertyKey }:${ search }`;
    }
    return '';
  };
}

export function AutocompleteOptionsGetByFilterParameterAdapterFactory(
  mapper: (search?: string | null) => string = AutocompleteOptionsMapperFactory(),
) {
  return (parameters: AutocompleteOptionsFromMethodDirectiveParameters): OpenApiRemoteMethodParameter<{
    page: number;
    size: number;
    sort: string;
    order: string;
    filter: string;
  }, void> => {
    return {
      parameters: {
        ...parameters,
        page: 0,
        size: 10,
        sort: '__updatedAt',
        order: 'desc',
        filter: mapper(parameters.search),
      },
    };
  };
}

export function AutocompleteOptionsGetByFilterResultAdapterFactory<Entity extends {
  uuid?: string
}>(transformer: (items: Entity[]) => ControlOptions) {
  return ({entities}: { entities: Entity[] }) => {
    const filteredEntities = entities.filter(entity => '__archived' in entity ? !entity.__archived : true);
    return transformer(filteredEntities);
  };
}

export function ResolveByUuidParameter(parameters: { value: string }) {
  console.log('ResolveByUuidParameter', parameters);
  const {value} = parameters;
  return {
    parameters: {
      uuid: value,
    },
  };
}

export function ResolveByUuidResultFactory<Entity>(transformer: (entity: Entity) => ControlOption) {
  return (entity: Entity) => {
    return transformer(entity);
  };
}
